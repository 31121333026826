var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "listing-detail-body"
  }, [_c('div', {
    staticClass: "listing-detail-header"
  }, [_c('div', {
    staticClass: "url-container"
  }, [_c('p', {
    staticClass: "content-normal"
  }, [_vm._v(" " + _vm._s(_vm.getUrl()) + " ")]), _c('div', {
    staticClass: "url-container-button"
  }, [_c('BaseButton', {
    attrs: {
      "icon-left": "copy.svg",
      "background-color": '#4C8DD9',
      "icons-style": {
        filter: 'invert(100%) sepia(0%) saturate(0%)\n' + '                        hue-rotate(317deg) brightness(103%) contrast(102%)'
      },
      "label-color": '#fff',
      "label": _vm.$t('CopyLink')
    },
    on: {
      "click": function ($event) {
        return _vm.copyUrl();
      }
    }
  })], 1)]), _c('div', {
    staticClass: "url-container-button-mobile"
  }, [_c('BaseButton', {
    attrs: {
      "icon-left": "copy.svg",
      "background-color": '#4C8DD9',
      "icons-style": {
        filter: 'invert(100%) sepia(0%) saturate(0%)\n' + '                        hue-rotate(317deg) brightness(103%) contrast(102%)'
      },
      "label-color": '#fff',
      "label": _vm.$t('CopyLink')
    },
    on: {
      "click": function ($event) {
        return _vm.copyUrl();
      }
    }
  })], 1), _c('div', {
    staticClass: "listing-detail-options-container"
  }, [_c('div', {
    staticClass: "listing-detail-view-options"
  }, [_c('div', {
    staticClass: "toggle-wrapper justify-end",
    on: {
      "click": function ($event) {
        _vm.allInfo = !_vm.allInfo;
      }
    }
  }, [_vm.allInfo ? _c('img', {
    staticClass: "toggle-img",
    attrs: {
      "src": require("../../assets/images/toggle-active.svg"),
      "alt": ""
    }
  }) : _vm._e(), !_vm.allInfo ? _c('img', {
    staticClass: "toggle-img",
    attrs: {
      "src": require("../../assets/images/toggle-inactive.svg"),
      "alt": ""
    }
  }) : _vm._e(), _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(_vm._s(_vm.$t("AllInfo")))])]), _c('div', {
    staticClass: "toggle-wrapper justify-end",
    on: {
      "click": function ($event) {
        _vm.allImages = !_vm.allImages;
      }
    }
  }, [_vm.allImages ? _c('img', {
    staticClass: "toggle-img",
    attrs: {
      "src": require("../../assets/images/toggle-active.svg"),
      "alt": ""
    }
  }) : _vm._e(), !_vm.allImages ? _c('img', {
    staticClass: "toggle-img",
    attrs: {
      "src": require("../../assets/images/toggle-inactive.svg"),
      "alt": ""
    }
  }) : _vm._e(), _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(_vm._s(_vm.$t("AllImages")))])]), _c('div', {
    staticClass: "toggle-wrapper justify-end",
    on: {
      "click": function ($event) {
        _vm.includeFooter = !_vm.includeFooter;
      }
    }
  }, [_vm.includeFooter ? _c('img', {
    staticClass: "toggle-img",
    attrs: {
      "src": require("../../assets/images/toggle-active.svg"),
      "alt": ""
    }
  }) : _vm._e(), !_vm.includeFooter ? _c('img', {
    staticClass: "toggle-img",
    attrs: {
      "src": require("../../assets/images/toggle-inactive.svg"),
      "alt": ""
    }
  }) : _vm._e(), _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(_vm._s(_vm.$t("IncludeFooter")))])]), _c('BaseButton', {
    attrs: {
      "size": 'large',
      "icon-left": 'download_24_24.svg',
      "isLoading": _vm.pdfLoading,
      "icons-style": {
        filter: 'invert(100%) sepia(0%) saturate(0%)\n' + '                        hue-rotate(317deg) brightness(103%) contrast(102%)'
      },
      "label": _vm.$t('SaveAsPdf'),
      "primary": true
    },
    on: {
      "click": function ($event) {
        return _vm.print();
      }
    }
  }), _c('BaseButton', {
    attrs: {
      "size": 'large',
      "icon-left": 'print.svg',
      "label": _vm.$t('Print'),
      "primary": false
    },
    on: {
      "click": function ($event) {
        return _vm.printHTML();
      }
    }
  })], 1), _c('LanguageToggle', {
    staticStyle: {
      "height": "40px"
    }
  })], 1)]), _vm.listing ? _c('div', {
    staticClass: "listing-detail",
    attrs: {
      "id": "printMe"
    }
  }, [_c('div', {
    staticClass: "grid-container"
  }, [_c('div', {
    staticClass: "grid-header"
  }, [_vm.company && _vm.company.name ? _c('div', {
    staticClass: "logo-container"
  }, [_c('img', {
    staticClass: "logo-image",
    attrs: {
      "src": _vm.company.name,
      "alt": ""
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "listing-subheading"
  }, [_vm.listing.estateType ? _c('div', {
    staticClass: "estate-info"
  }, [_vm._v(" " + _vm._s(_vm.getEstateInfo()) + " ")]) : _vm._e(), _vm.listing.listingName ? _c('div', {
    staticClass: "listing-detail-content-header-name"
  }, [_vm._v(" " + _vm._s(_vm.listing.listingName) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "url-container-image-mobile-only"
  }, [_vm.listing.images && _vm.listing.images[0] ? _c('img', {
    staticClass: "primary-image-container",
    style: {
      objectPosition: _vm.company && _vm.company.prioritization ? _vm.company.prioritization : 'center'
    },
    attrs: {
      "src": _vm.chooseCoverImage,
      "alt": ""
    }
  }) : _vm._e()])]), _c('div', {
    staticClass: "test-class"
  }, [_vm.allInfo ? _c('div', {
    staticClass: "grid-left"
  }, [_c('div', {
    staticClass: "listing-detail-content-container"
  }, [_c('div', {
    staticClass: "url-container-image-regular"
  }, [_vm.listing.images && _vm.listing.images[0] ? _c('img', {
    staticClass: "primary-image-container",
    style: {
      objectPosition: _vm.company && _vm.company.prioritization ? _vm.company.prioritization : 'center'
    },
    attrs: {
      "src": _vm.chooseCoverImage,
      "alt": ""
    }
  }) : _vm._e()]), _c('div', {
    staticClass: "listing-detail-body-contents"
  }, [_vm.allInfo ? _c('div', {
    staticClass: "listing-detail-body-summary-section"
  }, [_c('div', {
    staticClass: "listing-detail-body-summary-header"
  }, [this.$i18n.locale === 'et' ? _c('p', {
    staticClass: "listing-detail-body-summary-header-title"
  }, [_vm._v(" " + _vm._s(_vm.listing.slogan) + " ")]) : _vm._e(), this.$i18n.locale === 'en' ? _c('p', {
    staticClass: "listing-detail-body-summary-header-title"
  }, [_vm._v(" " + _vm._s(_vm.listing.sloganENG ? _vm.listing.sloganENG : _vm.listing.slogan) + " ")]) : _vm._e(), _c('p', {
    staticClass: "content-normal ownership-type"
  }, [_vm._v(" " + _vm._s(_vm.ownershipFormsAndBuildingMaterials) + " ")])]), _c('div', {
    staticClass: "listings-detail-body-features-container"
  }, [_vm.additionalDetails ? _c('div', {
    staticClass: "listings-detail-body-features"
  }, [_c('p', {
    staticStyle: {
      "display": "inline"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("AdditionalDetails")) + ": ")]), _vm._v(" " + _vm._s(_vm.additionalDetails) + " ")]) : _vm._e(), _vm.kitchen ? _c('div', {
    staticClass: "listings-detail-body-features"
  }, [_c('p', {
    staticStyle: {
      "display": "inline"
    }
  }, [_vm._v(_vm._s(_vm.$t("Kitchen")) + ":")]), _vm._v(" " + _vm._s(_vm.kitchen) + " ")]) : _vm._e(), _vm.sanitaryRoom ? _c('div', {
    staticClass: "listings-detail-body-features"
  }, [_c('p', {
    staticStyle: {
      "display": "inline"
    }
  }, [_vm._v(_vm._s(_vm.$t("SanitaryRoom")) + ":")]), _vm._v(" " + _vm._s(_vm.sanitaryRoom) + " ")]) : _vm._e(), _vm.heatingAndVentilation ? _c('div', {
    staticClass: "listings-detail-body-features"
  }, [_c('p', {
    staticStyle: {
      "display": "inline"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("HeatingandVentilation")) + ": ")]), _vm._v(" " + _vm._s(_vm.heatingAndVentilation) + " ")]) : _vm._e(), _vm.connectionAndSecurity ? _c('div', {
    staticClass: "listings-detail-body-features"
  }, [_c('p', {
    staticStyle: {
      "display": "inline"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ConnectionAndSecurity")) + ": ")]), _vm._v(" " + _vm._s(_vm.connectionAndSecurity) + " ")]) : _vm._e(), _vm.surroundings ? _c('div', {
    staticClass: "listings-detail-body-features"
  }, [_c('p', {
    staticStyle: {
      "display": "inline"
    }
  }, [_vm._v(_vm._s(_vm.$t("Surroundings")) + ":")]), _vm._v(" " + _vm._s(_vm.surroundings) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "description"
  }, [_c('p', {
    staticClass: "listing-detail-body-description",
    domProps: {
      "innerHTML": _vm._s(_vm.listing.description)
    }
  }, [_vm._v(" " + _vm._s(_vm.listing.description) + " ")])])]) : _vm._e()])])]) : _vm._e(), _c('div', {
    class: {
      'grid-right': _vm.allInfo,
      'grid-without-info': !_vm.allInfo
    }
  }, [_c('div', {
    staticClass: "listing-detail-content-header"
  }, [_vm.price || _vm.listing.pricePerSquareMeter ? _c('div', {
    staticClass: "listing-detail-content-header-price-container"
  }, [_c('div', {
    staticClass: "listing-detail-content-header-price-wrapper"
  }, [_vm.price ? _c('p', {
    staticClass: "listing-detail-content-header-price"
  }, [_vm._v(" " + _vm._s(_vm.price) + " ")]) : _vm._e(), _vm.listing.pricePerSquareMeter ? _c('p', {
    staticClass: "listing-detail-content-header-price-per-m2"
  }, [_vm._v(" " + _vm._s(_vm.listing.pricePerSquareMeter) + " €/m2 ")]) : _vm._e(), _vm.listing.landArea && (_vm.listing.landArea.m2 > 1000 && _vm.listing.landArea.m2Selected || _vm.listing.landArea.ha >= 0.1 && _vm.listing.landArea.haSelected) && _vm.listing.estateType === 'land' ? _c('p', {
    staticClass: "listing-detail-content-header-price-per-m2"
  }, [_vm._v(" " + _vm._s(_vm.pricePerHa) + " €/ha ")]) : _vm._e()])]) : _vm._e(), _vm.listing.estateType !== 'land' ? _c('div', {
    class: {
      'listing-detail-info-cards': _vm.allInfo,
      'listing-detail-info-cards-without-wrap': !_vm.allInfo
    }
  }, [_c('div', {
    staticClass: "listing-detail-info-card-section"
  }, [_vm.listing.estateType !== 'garage' ? _c('div', {
    staticClass: "listing-detail-info-card"
  }, [_c('div', {
    staticClass: "svg-image",
    attrs: {
      "alt": ""
    }
  }), _c('p', {
    staticClass: "rooms-info"
  }, [_vm._v(" " + _vm._s(this.listing.rooms) + " " + _vm._s(this.listing.rooms === 1 ? _vm.$t("room") : _vm.$t("rooms").toLowerCase()) + " ")])]) : _vm._e(), _c('div', {
    staticClass: "listing-detail-info-card"
  }, [_c('div', {
    staticClass: "svg-image2",
    attrs: {
      "src": require('@/assets/images/area_24_24.svg'),
      "alt": ""
    }
  }), _c('p', {
    staticClass: "surface-info"
  }, [_vm._v(" " + _vm._s(this.listing.generalSurfaceArea) + " m2 ")])])]), _c('div', {
    staticClass: "listing-detail-info-card-section"
  }, [_vm.listing.estateType !== 'garage' ? _c('div', {
    staticClass: "listing-detail-info-card"
  }, [_c('div', {
    staticClass: "svg-image3",
    attrs: {
      "src": require('@/assets/images/floor_24_24.svg'),
      "alt": ""
    }
  }), _vm.listing.estateType !== 'house' && _vm.listing.estateType !== 'house_part' && _vm.listing.estateType !== 'cottage' ? _c('p', {
    staticClass: "floor-info"
  }, [_vm._v(" " + _vm._s(this.listing.floor) + _vm._s(_vm.listing.estateType !== "house" ? "/" : "") + _vm._s(this.listing.floors) + " " + _vm._s(_vm.$t("Floor").toLowerCase()) + " ")]) : _vm._e(), _vm.listing.estateType === 'house' || _vm.listing.estateType === 'house_part' || _vm.listing.estateType === 'cottage' ? _c('p', {
    staticClass: "floor-info"
  }, [_vm._v(" " + _vm._s(this.listing.floors) + " " + _vm._s(this.listing.floors === 1 ? _vm.$t("Floor").toLowerCase() : _vm.$t("FloorPlural").toLowerCase()) + " ")]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "listing-detail-info-card"
  }, [_c('div', {
    staticClass: "svg-image4",
    attrs: {
      "src": require('@/assets/images/actions_24_24.svg'),
      "alt": ""
    }
  }), _c('p', {
    staticClass: "built-info"
  }, [_vm._v(" " + _vm._s(_vm.$t("BuiltIn")) + " " + _vm._s(this.listing.buildYear) + " ")])])])]) : _vm._e(), _vm.allInfo ? _c('div', {
    staticClass: "google-map-container"
  }, [_c('GmapMap', {
    staticClass: "g-map",
    attrs: {
      "center": _vm.mapCoordinates,
      "zoom": 18,
      "map-type-id": "terrain"
    }
  }, [_c('GmapMarker', {
    attrs: {
      "id": "marker",
      "position": _vm.markerCoordinates,
      "clickable": true,
      "draggable": true
    }
  })], 1)], 1) : _vm._e(), _c('div', {
    class: {
      'listing-detail-body-details-section': _vm.allInfo,
      'listing-detail-body-details-section-no-info': !_vm.allInfo
    }
  }, [_c('div', {
    staticClass: "listing-detail-body-info-section"
  }, [_vm.allInfo ? _c('div', {
    staticClass: "listing-detail-body-main-info-container"
  }, _vm._l(_vm.mainInfo, function (item) {
    return _c('div', {
      key: item.key,
      staticClass: "listing-detail-body-main-info-item"
    }, [_c('p', [_vm._v(" " + _vm._s(item.key) + " ")]), item.value === _vm.listing.cadastralNumber ? _c('p', {
      staticClass: "content-font underline",
      on: {
        "click": function ($event) {
          return _vm.openCadastralLink(item.value);
        }
      }
    }, [_vm._v(" " + _vm._s(item.value) + " ")]) : item.value === _vm.listing.estateNumber ? _c('p', {
      staticClass: "content-font underline",
      on: {
        "click": function ($event) {
          return _vm.openEstateNumberLink(item.value);
        }
      }
    }, [_vm._v(" " + _vm._s(item.value) + " ")]) : _c('p', {
      staticClass: "content-font"
    }, [_vm._v(" " + _vm._s(item.value) + " ")])]);
  }), 0) : _vm._e()]), _vm.allInfo ? _c('div', {
    staticClass: "listing-detail-body-brokers-section"
  }, _vm._l(_vm.brokers, function (sharedCreator, index) {
    return _c('div', {
      key: 'broker-' + index,
      staticClass: "listing-detail-broker-card"
    }, [_c('div', {
      staticClass: "listing-detail-broker-card-image"
    }, [sharedCreator.profilePicture && sharedCreator.profilePicture.length ? _c('img', {
      staticStyle: {
        "height": "100%",
        "width": "100%",
        "object-fit": "cover"
      },
      attrs: {
        "src": sharedCreator.profilePicture[0].name,
        "alt": ""
      }
    }) : _vm._e()]), _c('div', {
      staticClass: "listing-detail-broker-info",
      staticStyle: {
        "display": "flex",
        "flex-direction": "column",
        "gap": "4px"
      }
    }, [_c('p', {
      staticClass: "listing-detail-broker-name"
    }, [_vm._v(" " + _vm._s(sharedCreator.name) + " ")]), _c('p', [_vm._v(_vm._s(sharedCreator.phoneNumber))]), _c('p', [_vm._v(_vm._s(sharedCreator.email))])])]);
  }), 0) : _vm._e()])])])])]), _vm.allImages ? _c('div', {
    staticClass: "listing-images-section"
  }, _vm._l(_vm.renderedImages, function (image, index) {
    return _c('div', {
      key: index,
      staticClass: "listing-image-container",
      attrs: {
        "id": "lightgallery"
      },
      on: {
        "click": function ($event) {
          return _vm.openImageGallery(index);
        }
      }
    }, [_c('img', {
      staticClass: "listing-image",
      attrs: {
        "src": image,
        "alt": ""
      }
    })]);
  }), 0) : _vm._e()]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }